import * as React from 'react'

import { renderers } from '@markdoc/markdoc'
import type { HeadersFunction, LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { json } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'

import Cta from '@/components/content/Cta'
import { cacheControl } from '@/http.server'
import { findLp } from '@/models/lp.server'
import { pageHelper } from '@/request'

type LoaderData = typeof loader

export async function loader({ request }: LoaderFunctionArgs) {
  const lp = findLp('docs')

  if (!lp) {
    throw new Response('Not Found', { status: 404 })
  }

  const { root } = pageHelper(request)

  return json(
    {
      lp: lp,
      root: root
    },
    {
      headers: {
        'Cache-Control': cacheControl
      }
    }
  )
}

export const headers: HeadersFunction = ({ parentHeaders }) => ({
  'Cache-Control': parentHeaders.get('Cache-Control') ?? ''
})

export const meta: MetaFunction<LoaderData> = ({ data }) => {
  if (!data) return []

  const {
    lp: { meta },
    root
  } = data

  const title = `${meta.title} — PlanetScale`
  const image = `${root}${meta.image}`

  return [
    { title },
    { name: 'description', content: meta.description },
    { name: 'robots', content: meta.robots },
    { property: 'og:url', content: meta.canonical },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: title },
    { property: 'og:image', content: image },
    { property: 'og:description', content: meta.description },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:site', content: '@PlanetScale' },
    { property: 'twitter:creator', content: '@PlanetScale' },
    { property: 'twitter:url', content: meta.canonical },
    { property: 'twitter:title', content: title },
    { property: 'twitter:description', content: meta.description },
    { property: 'twitter:image', content: image },
    { tagName: 'link', rel: 'canonical', href: meta.canonical }
  ]
}

export default function DocsIndex() {
  const { lp } = useLoaderData<LoaderData>()

  return (
    <>
      {renderers.react(lp.body, React, {
        components: {
          Cta
        }
      })}
    </>
  )
}
